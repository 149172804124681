/** @enum {string} */
export const HUB = {
  DEMO_PROGRESS: 'demoProgress',
  ENVELOPE_PROGRESS: 'EnvelopeProgress',
};

export enum EnvelopeStatus {
  DRAFT = 'Draft',
  SENT_TO_CLIENT = 'SentToClient',
  IN_PROGRESS = 'InProgress',
  REQUIRES_REVIEW = 'RequiresReview',
  READY_TO_COUNTERSIGN = 'ReadyToCountersign',
  FINALISING = 'Finalising',
  FINALISED = 'Finalised',
  DELETED = 'IncludeDeleted',
}

export enum EnvelopeOrderBy {
  CREATED_ON = 'CreatedOn',
  NAME = 'Name',
  ACCOUNT_NAME = 'AccountName',
  COUNTERSIGNED_ON = 'CountersignedOn',
  UPDATED_ON = 'UpdatedOn',
}

/** @enum {string} */
export const CUSTOMER_VOI_DECISION = {
  APPROVE: 'approve',
  REJECT: 'reject',
};

export enum CustomerVoiProgress {
  NOT_REQUIRED = '', // nothing
  NOT_STARTED = 'NotStarted',
  NOT_REVIEWED = 'NotReviewed', // VOI Review
  APPROVED = 'Approved', // VOI Approved
  REJECTED = 'Rejected', // VOI Rejected
  IN_PROGRESS = 'InProgress', // VOI in progress
}

export enum TransactionOutcome {
  NOT_CALCULATED = 'NotCalculated',
  PASSED = 'Passed',
  FAILED = 'Failed',
}

export enum PaymentOption {
  ACCOUNT_HOLDER_PAYS = 'AccountHolderPays',
  CUSTOMER_PAYS = 'CustomerPays',
}

export enum PricingType {
  PAY_AS_YOU_GO = 'PayAsYouGo',
  INVOICING = 'Invoicing',
  SUBSCRIPTION = 'Subscription',
}

export enum DocumentAction {
  READ = 'Read', // No button
  ACKNOWLEDGE = 'Acknowledge', // Make as read
  SIGN = 'Sign', // Sign
}

export enum SignatureType {
  SIGNATURE_WITH_VOI_DATE_TIMESTAMP = 'SignatureWithVoiDateTimeStamp', // Voi style signature
  SIGNATURE_ONLY = 'SignatureOnly', // Signature only
}

export enum CustomerDocumentProgress {
  NOT_STARTED = 'NotStarted',
  READ = 'Read',
  ACKNOWLEDGED = 'Acknowledged',
  SIGNED = 'Signed',
}

/** @enum {string} */
export const CUSTOMER_DOCUMENTS_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
};

/** @enum {string} */
export enum CustomerPaymentProgress {
  NOT_PAID = 'NOT_PAID',
  PAID = 'PAID',
  REFUNDED = 'REFUNDED',
}

// todo move into Envelope area
/** @enum {string} */
export enum NavItemStatus {
  ACTIVE = 'active',
  LOCKED = 'locked',
  COMPLETED = 'completed',
}

// todo move into Envelope area
/** @enum {string} */
export enum NavItemType {
  DOCUMENT = 'document',
  PAYMENT = 'payment',
  VERIFY = 'verify',
}

/** @enum {string} */
export enum PaymentStatus {
  PROCESSING = 'processing',
  SUCCEEDED = 'succeeded',
  CANCELED = 'canceled',
  REQUIRES_ACTION = 'requires_action',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
}

/** @enum {string} */
export const VERIFY_STATUS = {
  PROCESSING: 'processing',
  SUCCEEDED: 'succeeded',
  CANCELED: 'canceled',
  FAILED: 'failed',
};

/** @enum {string} */
export enum EnvelopeNotificationType {
  NONE = 'None', // no notification
  EMAIL = 'Email',
  SMS = 'Sms',
  BOTH = 'Email,Sms',
}

export enum ProductPackageType {
  ECAF_WITH_VOI = 'EcafWithVoi',
  VOI_ONLY = 'VoiOnly',
  SINGLE_ID_DOCUMENT_VERIFICATION = 'SingleIdDocumentVerification',
  DOCUMENT_SIGNING = 'DocumentSigning',
  DOCUMENT_PACK = 'DocumentPack',
  CLIENT_CARE_LETTER = 'ClientCareLetter',
  PDF_DOCUMENT_PACK = 'PdfDocumentPack',
}

export enum DocumentTypes {
  AUSTRALIAN_PASSPORT = 1,
  FOREIGN_PASSPORT = 2,
  IMMICARD = 3,
  BIRTH_CERTIFICATE = 5,
  CITIZENSHIP_CERTIFICATE = 6,
  DESCENT_CERTIFICATE = 7,
  GOVERNMENT_ISSUED_PHOTO_ID = 8,
  MEDICARE = 9,
  CENTRELINK = 10,
  GOVERNMENT_ISSUED_ID = 11,
  CHANGE_OF_NAME_DOCUMENT = 12,
  MARRIAGE_CERTIFICATE = 13,
  NEW_SOUTH_WALES_DRIVER_LICENCE = 14,
  NORTHERN_TERRITORY_DRIVER_LICENCE = 15,
  QUEENSLAND_DRIVER_LICENCE = 16,
  SOUTH_AUSTRALIA_DRIVER_LICENCE = 17,
  TASMANIA_DRIVER_LICENCE = 18,
  VICTORIA_DRIVER_LICENCE = 19,
  WESTERN_AUSTRALIA_DRIVER_LICENCE = 20,
  AUSTRALIAN_CAPITAL_TERRITORY_DRIVER_LICENCE = 21,
}

export enum ErrorBehavior {
  PROPAGATE = 'Propagate',
}

export enum DecisionOptions { APPROVE = 'approve', REJECT = 'reject', RETRY = 'retry' }

export const EcafDocumentTemplateId = '00000000-0000-0000-0000-0000000000D1';

export const CclDocumentTemplateId = '00000000-0000-0000-0000-0000000000E1';

export const LS_STORAGE_KEY = 'ls_envelope_key';

export const LS_ENVELOPE_STORAGE_KEY = 'ls_envelope';

export const LS_SEARCH_KEY = 'ls_search';

export const LS_LAST_SELECTED_ENVELOPE_KEY = 'ls_last_selected';

/** @enum {string} */
export const Environments = {
  PRODUCTION: 'production',
  SANDBOX: 'sandbox',
  DEVELOP: 'develop',
};
