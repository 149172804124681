import { RegistrationStatus } from 'state/Admin/types';
import { EnvelopeStatus, CustomerVoiProgress, NavItemStatus } from '../types/constants';

export const envelopeStatusConverter = (status) => {
  switch (status) {
    case EnvelopeStatus.FINALISED:
      return {
        label: 'Finalised',
        icon: 'CheckCircle',
        color: 'success',
      };
    case EnvelopeStatus.FINALISING:
      return {
        label: 'Finalising',
        icon: 'NavRightCircle',
        color: 'secondary',
      };
    case EnvelopeStatus.REQUIRES_REVIEW:
      return {
        label: 'VOI review',
        icon: 'FlagCircle',
        color: 'warn',
      };
    case EnvelopeStatus.IN_PROGRESS:
      return {
        label: 'In progress',
        icon: 'NavRightCircle',
        color: 'secondary',
      };
    case EnvelopeStatus.DRAFT:
      return {
        label: 'Draft',
        icon: 'NavRightCircle',
        color: 'base40',
      };
    case EnvelopeStatus.SENT_TO_CLIENT:
      return {
        label: 'Sent to client',
        icon: 'NavRightCircle',
        color: 'base60',
      };
    case EnvelopeStatus.DELETED:
      return {
        label: 'Deleted',
        icon: 'NavRightCircle',
        color: 'base20',
      };
    case EnvelopeStatus.READY_TO_COUNTERSIGN:
      return {
        label: 'Ready to sign',
        icon: 'NavRightCircle',
        color: 'warn',
      };
    default:
      return {
        label: 'Unknown',
        icon: 'Tag',
        color: 'warn',
      };
  }
};

export const voiReviewStatusConverter = (status) => {
  switch (status) {
    case CustomerVoiProgress.APPROVED:
      return {
        label: 'VOI Approved',
        icon: 'CheckCircle',
        color: 'success',
      };
    case CustomerVoiProgress.REJECTED:
      return {
        label: 'VOI Rejected',
        icon: 'WarningCircle',
        color: 'error',
      };
    case CustomerVoiProgress.NOT_REVIEWED:
      return {
        label: 'VOI Review',
        icon: 'FlagCircle',
        color: 'warn',
      };
      case CustomerVoiProgress.NOT_STARTED:
        return {
          label: 'Not started',
          icon: 'NavRight',
          color: 'secondary',
        };
      case CustomerVoiProgress.IN_PROGRESS:
        return {
          label: 'In Progress',
          icon: 'FlagCircle',
          color: 'warn',
        };
    case CustomerVoiProgress.NOT_REQUIRED:
    default:
      return {
        label: 'Not required',
        icon: 'CheckCircle',
        color: 'secondary',
      };
  }
};

export const navItemStatusConverter = (status) => {
  switch (status) {
    case NavItemStatus.COMPLETED:
      return {
        label: status,
        icon: 'CheckCircle',
        color: 'success',
      };
    case NavItemStatus.ACTIVE:
      return {
        label: status,
        icon: 'User',
        color: 'base40',
      };
    default:
      return {
        label: 'Unknown',
        icon: 'Lock',
        color: 'warn',
      };
  }
};

export const registrationsStatusConverter = (status) => {
  switch (status) {
    case RegistrationStatus.NEW:
      return {
        label: status,
        icon: 'NavRightCircle',
        color: 'base40',
      };
    case RegistrationStatus.ACCEPTED:
      return {
        label: status,
        icon: 'CheckCircle',
        color: 'success',
      };
    case RegistrationStatus.REJECTED:
      return {
        label: status,
        icon: 'FlagCircle',
        color: 'error',
      };
    default:
      return {
        label: 'Unknown',
        icon: 'Lock',
        color: 'warn',
      };
  }
};
